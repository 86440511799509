import {useReducer} from 'react'
import AccountCard from '../../../AccountCard'
import {AccountCardProps} from '../types'
import {reducer} from './reducer'
import {ActionType} from './actions'
import {initialState} from './initialState'
import {PammInvestorMinimumDepositRequiredPopup} from './components/Popups/PammInvestorMinimumDepositRequiredPopup'
import {toPammPortal} from './toPammPortal'
import invariant from 'tiny-invariant'
import {PammTauroMarketsAccount} from '../../../../../graphql/types'
import {DepositNeededBadge} from './components/badges/DepositNeededBadge'
import {PendingApprovalBadge} from './components/badges/PendingApprovalBadge'
import {PammInvestorPendingPopup} from './components/Popups/PammInvestorPendingPopup'

type PammTauroMarketsAccountCardProps = AccountCardProps & { account: PammTauroMarketsAccount}

export const PammTauroMarketsAccountCard: React.FC<PammTauroMarketsAccountCardProps> = ({account}: PammTauroMarketsAccountCardProps) => {
  const [
    {
      showMinimumDepositRequiredPopup,
      showPendingPopup,
    },
    dispatch
  ] = useReducer(reducer, {...initialState, account})

  const closeModals = () => {
    dispatch({type: ActionType.CloseModal})
  }

  const accountUnconnected = account.unconnected
  const accountBalance = account.balance ?? 0
  const isDepositNeeded = accountUnconnected && account.minimumDeposit && accountBalance < account.minimumDeposit
  const isUnconnected = account.unconnected && account.minimumDeposit && accountBalance >= account.minimumDeposit
  const isUnapproved = !isUnconnected && account.balance === 0

  const handleClick = () => {
    if (account != null && (isUnconnected || isUnapproved)) {
      dispatch({type: ActionType.Click})
    } else {
      invariant(account.id, 'Account id is required')
      toPammPortal(account.id)
    }
  }

  return (
    <div
      data-testid='pamm-tauro-markets-account-card'
    >
      <AccountCard
        account={account}
        badges={
          <>
            {isDepositNeeded && <DepositNeededBadge />}
            {isUnconnected && <PendingApprovalBadge />}
          </>
        }
        onClick={handleClick}
      />
      <PammInvestorMinimumDepositRequiredPopup
        account={account}
        onClose={closeModals}
        open={showMinimumDepositRequiredPopup}
      />
      <PammInvestorPendingPopup
        account={account}
        onClose={closeModals}
        open={showPendingPopup}
      />
    </div>
  )
}
