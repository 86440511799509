import {get, filter, some, includes, isEmpty, map, sortBy, find, toUpper, sumBy} from 'lodash'
import {accountTypes, accountSubtypes, clientTypes, whiteLabels} from '@bdswiss/common-enums'
import {config} from '../../config'
import {isWhiteLabel} from '.'
import messages from '../../assets/messages'
import {AccountTypeEnumIndices, IAccountSubtypeEnum} from '@bdswiss/common-enums/lib/esm/enums/account'
import {Account, AccountSubtype, ForexAccount, PammTauroMarketsManagerAccount} from '../../graphql/types'
import {Nullable, UnionToIntersection} from '../../shared/typescript'
import invariant from 'tiny-invariant'
// import moment from 'moment'

export function isDemoAccount({__typename} = {__typename: 0}) {
  return __typename ? accountTypes[__typename].isDemo : false
}

export function isForexAccount({__typename} : {__typename?: string}) {
  if (__typename == null) return false
  return ![
    accountTypes.PAMMAccount.key,
    accountTypes.PAMMSMAccount.key,
    accountTypes.PAMMMauritiusAccount.key,
    accountTypes.PAMMVanuatuAccount.key,
    accountTypes.PAMMSMMauritiusAccount.key,
    accountTypes.PortfolioManagementAccount.key,
    accountTypes.PortfolioManagementMauritiusAccount.key,
    accountTypes.IntroducingBrokerSwissMarketsAccount.key,
    accountTypes.IntroducingBrokerAccount.key,
    accountTypes.IntroducingBrokerSwissMarketsMauritiusAccount.key,
    accountTypes.IntroducingBrokerSwissMarketsVanuatuAccount.key,
    accountTypes.IntroducingBrokerMauritiusAccount.key,
    accountTypes.IntroducingBrokerEquityFlowAccount.key,
    accountTypes.IntroducingBrokerBDSwissVanuatuAccount.key,
    accountTypes.BondAccount.key,
    accountTypes.BondDemoAccount.key,
    accountTypes.BondMauritiusAccount.key,
    accountTypes.BondMauritiusDemoAccount.key,
    accountTypes.BondVanuatuAccount.key,
    accountTypes.BondVanuatuDemoAccount.key,
  ].includes(__typename as AccountTypeEnumIndices) &&
    filter(accountTypes, (a) => includes(['forexMt4', 'forexMt5'], a.category)).map((c) => c.key).includes(__typename as AccountTypeEnumIndices)
}
export function getAccountSubtype(subType?: AccountSubtype): IAccountSubtypeEnum {
  const {key} = config
  const whiteLabelObj = find(whiteLabels, (wl) => wl.value === key)
  const subtypes = get(whiteLabelObj, 'supportedSubtypes') || accountSubtypes
  const selectedSubtype = find(subtypes, (t: IAccountSubtypeEnum ) => t.value === toUpper(subType) || t.key === subType || t.label === toUpper(subType))
  return selectedSubtype as unknown as IAccountSubtypeEnum
}
export function isMAMAccount({__typename}) {
  return get(accountTypes, [__typename, 'category']) === 'mam'
}

export function isMAMMasterAccount({__typename, accountSubtype}) {
  return get(accountTypes, [__typename, 'category']) === 'mam' &&
    accountSubtype === accountSubtypes.master.key
}

export function isMAMScSubAccount({__typename, accountSubtype}: {__typename?: Nullable<string>, accountSubtype?: Nullable<string>}): boolean {
  if (accountSubtype == null) return false

  return get(accountTypes, [__typename ?? '', 'category']) === 'mam' &&
    accountSubtype === accountSubtypes.sc_sub.key
}

export function isPremiumAccount({__typename}) {
  return includes([
    accountTypes.ForexPremiumMauritiusAccount.key,
    accountTypes.ForexPremiumVanuatuAccount.key,
  ], __typename)
}

export function isPAMMFundManagerAccount({__typename} : {__typename?: string}) {
  if (__typename == null)  return false

  return [
    accountTypes.PAMMFundManagerAccount.key,
    accountTypes.PAMMSMFundManagerAccount.key,
    accountTypes.PAMMFundManagerMauritiusAccount.key,
    accountTypes.PAMMSMFundManagerMauritiusAccount.key,
    accountTypes.PAMMSMFundManagerVanuatuAccount.key,
    accountTypes.PAMMFundManagerVanuatuAccount.key,
  ].includes(__typename as AccountTypeEnumIndices)
}

export function isPortfolioManagementAccount({__typename}) {
  return [
    accountTypes.PortfolioManagementAccount.key,
    accountTypes.PortfolioManagementMauritiusAccount.key,
  ].includes(__typename)
}

export function isPAMMCysecProduct({__typename}) {
  return [
    accountTypes.PAMMAccount.key,
    accountTypes.PAMMSMAccount.key,
  ].includes(__typename)
}

// TODO: This needs to be examined, we dont have tauromarkets in there! Also, any new whitelabel will be missing as well
export function isAffiliatesAccount({__typename}: {__typename?: string}) {
  if (__typename == null) return false
  return [
    accountTypes.AffiliateAccount.key,
    accountTypes.AffiliateVanuatuAccount.key,
    accountTypes.AffiliateMauritiusAccount.key].includes(__typename as AccountTypeEnumIndices)
}

export function isIntroducingBrokerAccount({__typename}: {__typename?: string}): boolean {
  if (__typename == null) return false
  return [accountTypes.IntroducingBrokerAccount.key,
    accountTypes.IntroducingBrokerSwissMarketsAccount.key,
    accountTypes.IntroducingBrokerMauritiusAccount.key,
    accountTypes.IntroducingBrokerEquityFlowAccount.key,
    accountTypes.IntroducingBrokerSwissMarketsMauritiusAccount.key,
    accountTypes.IntroducingBrokerSwissMarketsVanuatuAccount.key,
    accountTypes.IntroducingBrokerBDSwissVanuatuAccount.key,
  ].includes(__typename as AccountTypeEnumIndices)
}

export function isTradesmarterAccount({__typename} : Account): boolean {
  if (__typename == null) return false

  return filter(accountTypes, {category: 'tradeSmarter'}).map((c) => c.key).includes(__typename as AccountTypeEnumIndices)
}

export function isBitnukAccount({__typename}: {__typename?: string}): boolean {
  if (__typename == null) return false
  return filter(accountTypes, {subCategory: 'bitnuk'}).map((c) => c.key).includes(__typename as AccountTypeEnumIndices)
}
export function isKartwheelAccount(account?: Account): boolean {
  if (account?.__typename == null) return false

  const accountType = accountTypes[account.__typename]
  if (accountType.category === 'kartwheel') {
    return true
  }
  return false
}

export function isPammManagerAccount(account?: Account): account is PammTauroMarketsManagerAccount {
  if (account?.__typename == null) return false

  const accountType = accountTypes[account.__typename]
  return accountType.productId === accountTypes.PAMMTauroMarketsManagerAccount.productId || accountType.productId === accountTypes.PAMMTauroMarketsMauritiusManagerAccount.productId
}

export function isPammManagerTradeAccount(account?: Account) {
  if (!account) return false
  if (!account.metadata) return false

  const parsedMetadata = JSON.parse(account.metadata)
  return !!parsedMetadata.pammTradesAccount
}

export function isPammInvestorAccount(account: {__typename: AccountTypeEnumIndices}) {
  const accountType = accountTypes[account.__typename]
  return accountType.productId === accountTypes.PAMMTauroMarketsAccount.productId || accountType.productId === accountTypes.PAMMTauroMarketsMauritiusAccount.productId
}
export function blockedDepositAccount(account: ForexAccount): boolean {
  const {isViewOnly, isArchived, metadata, __typename} = account
  if (metadata) {
    const parsedMetadata = JSON.parse(metadata)
    const pammTradesAccount = parsedMetadata.pammTradesAccount
    if (pammTradesAccount) return true
  }
  if (isViewOnly || isArchived) return true
  invariant(__typename, 'Missing __typename in account')
  return includes(filter(accountTypes, {clientDepositPermitted: false, isDemo:false}).map((c) => c.key),__typename as AccountTypeEnumIndices)
}

export function blockedWithdrawalAccount(account: ForexAccount): boolean {
  const {metadata, __typename} = account
  if (metadata) {
    const parsedMetadata = JSON.parse(metadata)
    const pammTradesAccount = parsedMetadata.pammTradesAccount
    if (pammTradesAccount) return true
  }
  invariant(__typename, 'Missing __typename in account')
  return includes(filter(accountTypes, {clientWithdrawalPermitted: false, isDemo:false}).map((c) => c.key),__typename as AccountTypeEnumIndices)
}

export function isWalletAccountClientType(clientType) {
  return [clientTypes.affiliate.value,
    clientTypes.affiliateCorporate.value,
    clientTypes.ib.value,
    clientTypes.ibCorporate.value,
  ].includes(clientType)
}

export function hasOnlyDepositBlockedAccounts(accounts) {
  const otherAccounts = filter(accounts, (__typename) => !blockedDepositAccount(__typename))
  return accounts && otherAccounts.length === 0
}

export function hasAffiliateAccount(accounts) {
  const affiliateAccount = filter(accounts, (__typename) => isAffiliatesAccount(__typename))
  return accounts && affiliateAccount.length > 0
}

export function hasIntroducingBrokerAccount(accounts) {
  const ibAccount = filter(accounts, (__typename) => isIntroducingBrokerAccount(__typename))
  return accounts && ibAccount.length > 0
}

export function isMt5ForexAccount(__typename) {
  return get(accountTypes, [__typename, 'category']) === 'forexMt5'
}

export function isForexBdxAccount({__typename}) {
  return filter(accountTypes, {subCategory: 'bdx'}).map((c) => c.key).includes(__typename)
}

export function isSMPrimeWalletAccount({__typename}) {
  return filter(accountTypes, {category: 'smPrime'}).map((c) => c.key).includes(__typename)
}

export function isEmptySubscriptionAccount(account) {
  return  includes(filter(accountTypes, {subscription: true}).map((c) => c.key),account.__typename) ? isEmpty(account.subscription) : false
}

export function isAffiliateClient(clientType) {
  return [
    clientTypes.affiliate.value,
    clientTypes.affiliateCorporate.value,
  ].includes(clientType)
}

export function isIBClient(clientType) {
  return [
    clientTypes.ib.value,
    clientTypes.ibCorporate.value,
  ].includes(clientType)
}

export function hasWalletProductAccount(accounts) {
  const walletProductAccount = filter(accounts, (a) => get(accountTypes[a.__typename], 'walletProduct'))
  return accounts && walletProductAccount.length > 0
}

export function hasVipOrRaw(accounts, demo?) {
  return some(accounts, (a) => (demo ? a.isDemo : !a.isDemo) && includes([accountSubtypes.black.key, accountSubtypes.raw.key], a.accountSubtype))
}

export function isVipOrRaw(account, type?, demo?) {
  return includes([accountSubtypes.black.key, accountSubtypes.raw.key], get(account,'accountSubtype'))
    || (type && (!demo || (demo && type.isDemo)) && includes([accountSubtypes.black.key, accountSubtypes.raw.key], get(account,'key')))
}

export function hasVipAccount(accounts) {
  return some(accounts, (a) => includes([accountSubtypes.black.key], a.accountSubtype))
}

export function hasOnlyWalletProductAccount(accounts) {
  const walletProductAccount = filter(accounts, (a) => get(accountTypes[a.__typename], 'walletProduct'))
  return accounts && walletProductAccount.length === accounts.length
}

export function isCentAccount({__typename}) {
  return [
    accountTypes.CentMauritiusAccount.key,
    accountTypes.CentVanuatuAccount.key,
  ].includes(__typename)
}

export function getAllowedAccounts(accounts, companyObject, t, allowedAccountTypes, client) {
  const {key} = config
  if (!accounts) return []
  const allowedCategories = ['forexMt4', 'forexMt5', 'pammForex', 'cent', 'pamm']
  const hasWalletProduct = get(client, 'hasPartnerAccount')
  const whiteLabel = isWhiteLabel()
  const whiteLabelSupportedSubtypes : any = whiteLabel && map(get(whiteLabels[key], 'supportedSubtypes'), 'value')
  const country = toUpper(get(client, 'address.country'))
  const allowedAccount = filter(accountTypes, (a: any) => {
    if (a.company !== companyObject.key) {
      return false
    }

    if (a.deprecated) {
      return false
    }
    // TODO: temp hide demo account from the list SKYG-753
    if (a.isDemo && config.visual?.accounts?.hideDemoAccountSelection) {
      return false
    }
    if (!a.clientOpenPermitted) {
      return false
    }
    // https://skyground.atlassian.net/browse/SKYG-2141
    if (a.productId === accountTypes.PAMMTauroMarketsManagerAccount.productId || a.productId === accountTypes.PAMMTauroMarketsMauritiusManagerAccount.productId) {
      return ['ib', 'ibCorporate', 'corporate', 'affiliate', 'affiliateCorporate'].includes(client.clientType)
    }

    if (!(
      (
        includes(allowedCategories, a.category) &&
          (a.isDemo === false || a.openSeperateDemo) &&
          (!a.subCategory || (a.subCategory && (a.subCategory === 'premium' || a.subscription)))
      ) ||
        (
          a.walletProduct && !hasWalletProduct
        )
    )) {
      return false
    }
    const isBaseProduct = (whiteLabel && whiteLabels[key].baseProducts ? includes(whiteLabels[key].baseProducts, a.key) : true)
    if (!isBaseProduct) {
      return false
    }
    const notInDisallowedCountries = !a.disallowedCountries || (a.disallowedCountries &&
      (
        a.manuallyAllowed
          ? ((a.clientPermittedByCountry && !includes(a.disallowedCountries, country)) || includes(client.manuallyAllowedAccounts, a.key))
          : !includes(a.disallowedCountries, country)
      ))
    if (!notInDisallowedCountries) {
      return false
    }

    const clientAllowedToCreateMoreAccounts = !a.clientMaxAllowed || //@ts-ignore
      (a.clientMaxAllowed && sumBy(accounts, (account) => account.__typename === a.key) < a.clientMaxAllowed)
    if (!clientAllowedToCreateMoreAccounts) {
      return false
    }


    const restrictedAccountTypes = config.restrictedAccountTypes
    const accountType: AccountTypeEnumIndices = a.value
    if (restrictedAccountTypes && restrictedAccountTypes[accountType]) {
      const restrictions = restrictedAccountTypes[accountType]
      const isIncludedClient = restrictions.clientIds.includes(client.id)
      if (isIncludedClient) {
        return true
      }
      if (!restrictions.includeAffiliateFollowers) {
        return false
      }
      const affiliateIds: number[] = client.registrationCampaigns?.map(x => x.affiliateId) ?? []
      for (const id of affiliateIds) {
        if (restrictions.clientIds.includes(id)) {
          return true
        }
      }
      return false
    }

    return true
  }
  )

  const allowedAccountsList : any[]= []
  map(allowedAccount, (type: any) => {
    const baseProduct = type.baseProduct && type.baseProduct.replace(/^\w/, c => c.toLowerCase())
    if (type.supportedSubtypes && !type.subscription) {
      map(type.supportedSubtypes, (subType) => {
        if (!isEmpty(whiteLabelSupportedSubtypes) && !includes(whiteLabelSupportedSubtypes, subType)) {
          return
        }
        const selectedSubType : any = getAccountSubtype(subType)
        if (!isEmpty(allowedAccountTypes) && !includes(allowedAccountTypes, selectedSubType.key) && !type.isDemo) {
          return
        }
        if (type.isDemo && selectedSubType.clientOpenPermittedDemo === false) {
          return
        }
        const clientAccountsLimitCount = get(filter(accounts, (a) => a.accountSubtype === selectedSubType.key &&
          a.isDemo === type.isDemo && !a.isViewOnly && !a.isArchived), 'length')
        const subTypeAlreadyExists = find(allowedAccountsList, (a) => a.accountSubtype === selectedSubType.key
           && (!selectedSubType.allowDemo || (selectedSubType.allowDemo && a.isDemo)) && (a.isDemo === type.isDemo))
        if (subTypeAlreadyExists) {
          subTypeAlreadyExists.accountTypesList && subTypeAlreadyExists.accountTypesList.push(type.key)
        } else {
          if (!selectedSubType.hideFromClients && (clientAccountsLimitCount < selectedSubType.maxAllowed || !selectedSubType.maxAllowed)) {
            const accountSubTypeObject = {
              label: `${selectedSubType.label}  ${t(messages.account.i18nKey)}`,
              icon: get(selectedSubType,'shortLabel') || type.shortLabel,
              accountSubtype: selectedSubType.key,
              accountType: type.key,
              accountTypesList: [type.key],
              category: type.category,
              subCategory: type.subCategory || '',
              baseProduct,
              walletProduct: type.walletProduct,
              maxAllowed: selectedSubType.maxAllowed,
              available: (selectedSubType.maxAllowed) ? selectedSubType.maxAllowed - clientAccountsLimitCount : '',
              isDemo: type.isDemo,
              showDemoLabel: type.showDemoLabel,
            }
            allowedAccountsList.push(accountSubTypeObject)
          }
        }
      })
    } else {
      const clientAccountsLimitCount = get(filter(accounts, (a) => a.__typename === type.key && !type.supportsMultipleAccounts), 'length')
      if (clientAccountsLimitCount === 0 || !clientAccountsLimitCount) {
        const account = {
          label:`${type.label}`,
          icon: type.shortLabel,
          accountType: type.key,
          category: type.category,
          subCategory: type.subCategory || '',
          baseProduct,
          walletProduct: type.walletProduct,
          isDemo: type.isDemo,
        }
        allowedAccountsList.push(account)
      }

    }
  })
  const result = sortBy(allowedAccountsList, (a) => !!a.walletProduct)
  return result
}


export function getForcedServerName(account, Images?) {
  const forcedServerNameDetails = config.serverNameForced
  let forcedServerName = null
  let forcedServerExampleImage = null
  if (forcedServerNameDetails) {
    // const validDetails =  ((moment(account.createdAt) < moment(forcedServerNameDetails.date))
    // && (moment() < moment(forcedServerNameDetails.validUntil)))
    forcedServerName = get(forcedServerNameDetails.serverName, account.server)
    forcedServerExampleImage =  get(Images,get(forcedServerNameDetails.exampleImages,  account.server))
  }
  return {forcedServerName, forcedServerExampleImage}
}

export const isViewOnlyAccount = (account: Account): boolean => 'isViewOnly' in account && Boolean(account.isViewOnly)

export const isApprovedAccount = (account: Account): boolean => 'approved' in account && Boolean(account.approved)

export function getAccountLabel(account: Account, locale: string): string {
  const {hasAnotherCurrenciesSymbols} = config

  const accountType = accountTypes[account.__typename ?? ''] || ('type' in account ? accountTypes[account.type as string] : null)

  const tradeSmarter = isViewOnlyAccount(account) && isTradesmarterAccount(account) && 'Wallet'
  if (!hasAnotherCurrenciesSymbols) {
    return tradeSmarter || get(account, 'accountName') || accountType.localization.t(locale) || accountType.label
  }

  const label = tradeSmarter || get(account, 'accountName') || accountType.localization.t(locale) || accountType.label
  return label.replace(/USD/g, 'GUSD').replace(/EUR/g, 'GEUR')
}

export function hasBinaryProductAccount(accounts) {
  const binaryProductAccount = filter(accounts, (a) => get(accountTypes[a.__typename], 'category') === 'tradeSmarter')
  return accounts && binaryProductAccount.length > 0
}


export function checkAccountCopyTrading(account) {
  const {featuresConfig:{copyTradingEnabled}} = config
  const accountTypeName = get(account,'__typename')
  const accountType = accountTypeName && accountTypes[account.__typename]
  const accountSubtype = get(account,'accountSubtype')
  return includes(get(copyTradingEnabled,'accountSubTypes',[]), accountSubtype) &&
  includes(get(copyTradingEnabled,'accountTypesCategory',[]), accountType.category)
  && !account.isDemo && !account.isViewOnly  && !account.isArchived
}

export function isWalletAccount(__typename) {
  return !!get(accountTypes[__typename], 'walletProduct')
}

export function hasValidAccountSubTypes(accounts, accountSubTypes, notReadOnly = false, nodeDemo = null) {
  return some(accounts, (a) => (includes(accountSubTypes, a.accountSubtype)
  && (!notReadOnly || (notReadOnly && !a.isReadOnly))
  && (!nodeDemo || (nodeDemo && !a.isDemo))))
}

export function getAccountTypeForStats(account) {
  if (account.category === 'cent') return 'cent'
  return account.accountSubtype
}

export const isConnectedPammInvestorAccount = (account) =>
  (account.__typename === accountTypes.PAMMTauroMarketsAccount.key) &&
    !account.unconnected

export const getAccountProperty = <T extends Account, K extends keyof UnionToIntersection<T>>
  (obj: T, key: K):  K extends keyof T ? T[K] : (UnionToIntersection<T>[K] | undefined )=>
    key in obj ? obj[key as string] : undefined
